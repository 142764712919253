.App-footer {
    background-color: #2e2e2e;
    color: #ffffff;
    margin: 0;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.App-footer p {
    background-color: #2e2e2e;
    color: #ffffff;
    margin: 0;
    font-family: 'Playfair Display', serif;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
}