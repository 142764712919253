.Portada {
    width: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 25px;
    background-size: cover;
    background-position: center;
    min-height: calc(80vh - 200px);
}

.Portada img {
    height: 300px;
}

.Portada h1 {
    color: #a6a6a2;
    text-shadow: 0px 0px 3px rgba(0,0,0,.3);
    font-family: 'Playfair Display', serif;
    font-size: 52px;
    font-weight: 400;
    max-width: 304px;
    text-align: center;
    margin: 25px 0 0 0;
}

@media(max-width: 650px) {
    .Portada h1 {
        font-size: 41px;
    }
}

@media(max-width: 300px) {
    .Portada img {
        height: auto;
        max-width: calc(100% - 20px);
    }
    
    .Portada h1 {
        font-size: 36px;
    }
}

@media(max-width: 270px) { 
    .Portada h1 {
        font-size: 28px;
    }
}