@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&family=Playfair+Display&display=swap');

/*
font-family: 'Open Sans', sans-serif;
font-family: 'Playfair Display', serif;
*/

* {
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}

*:focus {
  outline: none;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}