.Contacto {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 25px;
    width: calc(100% - 50px);
    background-color: #f5f5f5;
}

.Contacto h2 {
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    font-size: 36px;
    margin: 0 50px;
    text-align: center;
    color: #02275c;
}

.Contacto-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1100px;
}

.Contacto-content p {
    font-family: 'Open Sans', sans-serif;
    font-size: 21px;
    margin: 25px;
    width: 100%;
    max-width: 600px;
    color: #02275c;
    text-align: justify;
}

.Contacto-content p p {
    font-size: 18px;
    margin: 25px 0 25px 0;
    font-weight: 500;
}

.Contacto-content p p img {
    margin: 0 25px 0 0;
}

.Contacto-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, rgba(0, 0, 0, .4), rgba(0, 0, 0, .5));
    padding: 20px 25px;
    margin: 25px;
    width: calc(100% - 50px);
    max-width: 440px;
}

.Contacto-form p {
    color: white;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    margin: 0 10px 10px 10px !important;
}

.Contacto-form input {
    width: calc(100% - 20px);
    height: 33px;
    border: none;
    margin: 3px;
    padding: 0 10px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

.Contacto-form input::placeholder {
    font-style: italic;
    font-weight: 500;
}

.Contacto-form textarea {
    resize: none;
    width: calc(100% - 20px);
    height: 80px;
    border: none;
    margin: 3px;
    padding: 5px 10px !important;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

.Contacto-form-submit {
    width: 100% !important;
    height: 45px !important;
    background-color: #02275c;
    color: white;
    font-weight: 700 !important;
    font-size: 18px;
    padding: 0;
    cursor: pointer;
    transition: .3s ease-in-out;
}

.Contacto-social {
    width: 100%;
    max-width: 1085px;
    display: flex;
    align-items: center;
    margin-top: 25px;
}

.Contacto-social a {
    width: 40px;
    height: 40px;
    margin: 10px;
    transition: .3s ease-in-out;
}

.Contacto-social img {
    width: 40px;
    height: 40px;
    filter: invert(8%) sepia(33%) saturate(7468%) hue-rotate(210deg) brightness(101%) contrast(98%);
}

.Contacto-modal {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.8);
}

.Contacto-modal img {
    width: 100%;
    max-width: 375px;
}

.Contacto-modal p {
    font-size: 21px;
    font-weight: 400;
    width: calc(100% - 30px);
    max-width: 250px;
    color: white;
    font-style: normal;
    margin-top: -60px !important;
    padding: 0 20px;
    text-align: center;
}


@media(hover:hover) {
    .Contacto-form-submit:hover {
        transform: translateY(-1px);
        box-shadow: 0px 2px 6px rgba(0,0,0,.3);
    }

    .Contacto-social a:hover {
        transform: translateY(-2px);
    }
}

@media(max-width: 1150px) {
    .Contacto-content p {
        margin: 25px 25px 25px 15px;
    }
}

@media(max-width: 900px) {
    .Contacto {
        padding: 50px 25px 25px 25px;
    }

    .Contacto h2 {
        margin: 0 50px -25px 50px;
    }

    .Contacto-content {
        flex-direction: column;
    }

    .Contacto-content p {
        margin: 0 0 25px 0;
        width: calc(100% - 50px);
        max-width: 480px;
    }

    .Contacto-social {
        justify-content: center;
    }

    .Contacto-modal p {
        max-width: 250px;
        padding: 0 20px;
    }
}

@media(max-width: 650px) {
    .Contacto-content p {
        width: 100%;
    }
}

@media(max-width: 400px) {
    .Contacto h2 {
        margin: 0 0 -25px 0;
        font-size: 28px;
    }

    .Contacto-content {
        flex-direction: column;
    }

    .Contacto-content p {
        font-size: 16px;
        margin: 0 0 25px 0;
    }

    .Contacto-form p {
        font-size: 16px;
    }

    .Contacto-form-submit {
        font-size: 16px;
    }

    .Contacto-social {
        justify-content: center;
    }

    .Contacto-modal p {
        max-width: 200px;
        margin-top: -20vw !important;
        padding: 0 20px;
    }
}

@media(max-width: 300px) {
    .Contacto h2 {
        font-size: 26px;
    }

    .Contacto-form-submit {
        height: 35px !important;
    }
}

@media(max-width: 270px) {
    .Contacto h2 {
        font-size: 24px;
    }

    .Contacto-content p {
        font-size: 14px;
    }

    .Contacto-form p {
        font-size: 14px;
    }

    .Contacto-modal p {
        max-width: 160px;
    }
}