@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&family=Playfair+Display&display=swap);
/*
font-family: 'Open Sans', sans-serif;
font-family: 'Playfair Display', serif;
*/

* {
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}

*:focus {
  outline: none;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}
.App-footer {
    background-color: #2e2e2e;
    color: #ffffff;
    margin: 0;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.App-footer p {
    background-color: #2e2e2e;
    color: #ffffff;
    margin: 0;
    font-family: 'Playfair Display', serif;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
}
.Portada {
    width: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 25px;
    background-size: cover;
    background-position: center;
    min-height: calc(80vh - 200px);
}

.Portada img {
    height: 300px;
}

.Portada h1 {
    color: #a6a6a2;
    text-shadow: 0px 0px 3px rgba(0,0,0,.3);
    font-family: 'Playfair Display', serif;
    font-size: 52px;
    font-weight: 400;
    max-width: 304px;
    text-align: center;
    margin: 25px 0 0 0;
}

@media(max-width: 650px) {
    .Portada h1 {
        font-size: 41px;
    }
}

@media(max-width: 300px) {
    .Portada img {
        height: auto;
        max-width: calc(100% - 20px);
    }
    
    .Portada h1 {
        font-size: 36px;
    }
}

@media(max-width: 270px) { 
    .Portada h1 {
        font-size: 28px;
    }
}
.Nosotros {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 125px 25px;
    width: calc(100% - 50px);
    background-color: #f5f5f5;
}

.Nosotros h2 {
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    font-size: 36px;
    margin:  0 50px 25px 50px;
    text-align: center;
}

.Nosotros p {
    font-family: 'Open Sans', sans-serif;
    max-width: 1101px;
    margin: 0 25px;
    text-align: justify !important;
}

@media(max-width: 1000px) {
    .Nosotros {
        padding: 100px 25px;
    }
}

@media(max-width: 650px) {
    .Nosotros h2 {
        margin: 0 0 25px 0;
    }

    .Nosotros p {
        margin: 0;
    }
}

@media(max-width: 500px) {
    .Nosotros {
        padding: 50px 25px;
    }
}

@media(max-width: 400px) {
    .Nosotros h2 {
        font-size: 28px;
    }
}

@media(max-width: 300px) {
    .Nosotros h2 {
        font-size: 26px;
    }
}

@media(max-width: 270px) {
    .Nosotros h2 {
        font-size: 24px;
    }
}
.Especialidades {
    padding: 125px 25px;
    background-color: rgba(2, 39, 92, 0.9);
}

.Especialidades h2 {
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    font-size: 36px;
    margin: 0 50px 50px 50px;
    text-align: center;
    color: white;
    text-shadow: 0px 2px 3px rgba(0,0,0,.5);
}

.Especialidades-content {
    margin: 55px 0 55px 0;
    display: flex;
    justify-content: center;
}

.Especialidades-element {
    margin: 10px;
    background-color: rgba(255,255,255,.9);
    box-shadow: 0px 3px 9px #00000059;
    cursor: pointer;
    transition: .3s ease-in-out;
    width: 250px;
    padding: 25px 0;
}

.Especialidades-element-img {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Especialidades-element-img img {
    -webkit-filter: invert(9%) sepia(38%) saturate(6073%) hue-rotate(210deg) brightness(94%) contrast(98%);
            filter: invert(9%) sepia(38%) saturate(6073%) hue-rotate(210deg) brightness(94%) contrast(98%);
}

.Especialidades-element-title {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Especialidades-element h4 {
    color: #02275c;
    font-family: 'Playfair Display', serif;
    font-size: 24px;
    text-align: center;
    margin: 0;
    flex: 0 1;
}

.Especialidades-cards-buttons {
    margin-top: 0;
}

.Especialidades-cards-button, .Especialidades-cards-button-selected {
    margin: 3px;
    border: none;
    width: 25px;
    height: 3px;
    cursor: pointer;
    transition: .3s ease-in-out;
}

.Especialidades-cards-button {
    background-color: #D9D9D9;
}

.Especialidades-cards-button-selected {
    background-color: #02275c;
}

@media(hover:hover) and (min-width: 1121px) {
    .Especialidades-element:hover {
        -webkit-transform: translateY(-3px);
                transform: translateY(-3px);
        box-shadow: 0px 6px 12px #00000059;
    }
}

@media(max-width: 1150px) {
    .Especialidades-content {
        max-width: 800px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: auto;
    }
}

@media(max-width: 600px) {
    .Especialidades h2 {
        margin-left: 0;
        margin-right: 0;
    }

    .Especialidades-element {
        width: 230px;
    }
}

@media(max-width: 500px) {
    .Especialidades {
        padding: 50px 25px ;
    }

    .Especialidades-content {
        margin: 25px 0;
    }

    .Especialidades-element {
        width: 210px;
    }

    .Especialidades-element h4 {
        font-size: 21px;
    }
}

@media(max-width: 400px) {
    .Especialidades h2 {
        font-size: 28px;
        margin-bottom: 10px;
    }

    .Especialidades-element {
        width: 190px;
        padding: 15px 0;
    }

    .Especialidades-element h4 {
        font-size: 20px;
    }
}

@media(max-width: 300px) {
    .Especialidades h2 {
        font-size: 26px;
    }

    .Especialidades-element-title {
        height: 70px;
    }
    
    .Especialidades-element h4 {
        font-size: 19px;
    }
}

@media(max-width: 270px) {
    .Especialidades h2 {
        font-size: 24px;
    }
}
.Contacto {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 25px;
    width: calc(100% - 50px);
    background-color: #f5f5f5;
}

.Contacto h2 {
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    font-size: 36px;
    margin: 0 50px;
    text-align: center;
    color: #02275c;
}

.Contacto-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1100px;
}

.Contacto-content p {
    font-family: 'Open Sans', sans-serif;
    font-size: 21px;
    margin: 25px;
    width: 100%;
    max-width: 600px;
    color: #02275c;
    text-align: justify;
}

.Contacto-content p p {
    font-size: 18px;
    margin: 25px 0 25px 0;
    font-weight: 500;
}

.Contacto-content p p img {
    margin: 0 25px 0 0;
}

.Contacto-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, rgba(0, 0, 0, .4), rgba(0, 0, 0, .5));
    padding: 20px 25px;
    margin: 25px;
    width: calc(100% - 50px);
    max-width: 440px;
}

.Contacto-form p {
    color: white;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    margin: 0 10px 10px 10px !important;
}

.Contacto-form input {
    width: calc(100% - 20px);
    height: 33px;
    border: none;
    margin: 3px;
    padding: 0 10px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

.Contacto-form input::-webkit-input-placeholder {
    font-style: italic;
    font-weight: 500;
}

.Contacto-form input:-ms-input-placeholder {
    font-style: italic;
    font-weight: 500;
}

.Contacto-form input::placeholder {
    font-style: italic;
    font-weight: 500;
}

.Contacto-form textarea {
    resize: none;
    width: calc(100% - 20px);
    height: 80px;
    border: none;
    margin: 3px;
    padding: 5px 10px !important;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

.Contacto-form-submit {
    width: 100% !important;
    height: 45px !important;
    background-color: #02275c;
    color: white;
    font-weight: 700 !important;
    font-size: 18px;
    padding: 0;
    cursor: pointer;
    transition: .3s ease-in-out;
}

.Contacto-social {
    width: 100%;
    max-width: 1085px;
    display: flex;
    align-items: center;
    margin-top: 25px;
}

.Contacto-social a {
    width: 40px;
    height: 40px;
    margin: 10px;
    transition: .3s ease-in-out;
}

.Contacto-social img {
    width: 40px;
    height: 40px;
    -webkit-filter: invert(8%) sepia(33%) saturate(7468%) hue-rotate(210deg) brightness(101%) contrast(98%);
            filter: invert(8%) sepia(33%) saturate(7468%) hue-rotate(210deg) brightness(101%) contrast(98%);
}

.Contacto-modal {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.8);
}

.Contacto-modal img {
    width: 100%;
    max-width: 375px;
}

.Contacto-modal p {
    font-size: 21px;
    font-weight: 400;
    width: calc(100% - 30px);
    max-width: 250px;
    color: white;
    font-style: normal;
    margin-top: -60px !important;
    padding: 0 20px;
    text-align: center;
}


@media(hover:hover) {
    .Contacto-form-submit:hover {
        -webkit-transform: translateY(-1px);
                transform: translateY(-1px);
        box-shadow: 0px 2px 6px rgba(0,0,0,.3);
    }

    .Contacto-social a:hover {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }
}

@media(max-width: 1150px) {
    .Contacto-content p {
        margin: 25px 25px 25px 15px;
    }
}

@media(max-width: 900px) {
    .Contacto {
        padding: 50px 25px 25px 25px;
    }

    .Contacto h2 {
        margin: 0 50px -25px 50px;
    }

    .Contacto-content {
        flex-direction: column;
    }

    .Contacto-content p {
        margin: 0 0 25px 0;
        width: calc(100% - 50px);
        max-width: 480px;
    }

    .Contacto-social {
        justify-content: center;
    }

    .Contacto-modal p {
        max-width: 250px;
        padding: 0 20px;
    }
}

@media(max-width: 650px) {
    .Contacto-content p {
        width: 100%;
    }
}

@media(max-width: 400px) {
    .Contacto h2 {
        margin: 0 0 -25px 0;
        font-size: 28px;
    }

    .Contacto-content {
        flex-direction: column;
    }

    .Contacto-content p {
        font-size: 16px;
        margin: 0 0 25px 0;
    }

    .Contacto-form p {
        font-size: 16px;
    }

    .Contacto-form-submit {
        font-size: 16px;
    }

    .Contacto-social {
        justify-content: center;
    }

    .Contacto-modal p {
        max-width: 200px;
        margin-top: -20vw !important;
        padding: 0 20px;
    }
}

@media(max-width: 300px) {
    .Contacto h2 {
        font-size: 26px;
    }

    .Contacto-form-submit {
        height: 35px !important;
    }
}

@media(max-width: 270px) {
    .Contacto h2 {
        font-size: 24px;
    }

    .Contacto-content p {
        font-size: 14px;
    }

    .Contacto-form p {
        font-size: 14px;
    }

    .Contacto-modal p {
        max-width: 160px;
    }
}
