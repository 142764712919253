.Especialidades {
    padding: 125px 25px;
    background-color: rgba(2, 39, 92, 0.9);
}

.Especialidades h2 {
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    font-size: 36px;
    margin: 0 50px 50px 50px;
    text-align: center;
    color: white;
    text-shadow: 0px 2px 3px rgba(0,0,0,.5);
}

.Especialidades-content {
    margin: 55px 0 55px 0;
    display: flex;
    justify-content: center;
}

.Especialidades-element {
    margin: 10px;
    background-color: rgba(255,255,255,.9);
    box-shadow: 0px 3px 9px #00000059;
    cursor: pointer;
    transition: .3s ease-in-out;
    width: 250px;
    padding: 25px 0;
}

.Especialidades-element-img {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Especialidades-element-img img {
    filter: invert(9%) sepia(38%) saturate(6073%) hue-rotate(210deg) brightness(94%) contrast(98%);
}

.Especialidades-element-title {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Especialidades-element h4 {
    color: #02275c;
    font-family: 'Playfair Display', serif;
    font-size: 24px;
    text-align: center;
    margin: 0;
    flex: 0;
}

.Especialidades-cards-buttons {
    margin-top: 0;
}

.Especialidades-cards-button, .Especialidades-cards-button-selected {
    margin: 3px;
    border: none;
    width: 25px;
    height: 3px;
    cursor: pointer;
    transition: .3s ease-in-out;
}

.Especialidades-cards-button {
    background-color: #D9D9D9;
}

.Especialidades-cards-button-selected {
    background-color: #02275c;
}

@media(hover:hover) and (min-width: 1121px) {
    .Especialidades-element:hover {
        transform: translateY(-3px);
        box-shadow: 0px 6px 12px #00000059;
    }
}

@media(max-width: 1150px) {
    .Especialidades-content {
        max-width: 800px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: auto;
    }
}

@media(max-width: 600px) {
    .Especialidades h2 {
        margin-left: 0;
        margin-right: 0;
    }

    .Especialidades-element {
        width: 230px;
    }
}

@media(max-width: 500px) {
    .Especialidades {
        padding: 50px 25px ;
    }

    .Especialidades-content {
        margin: 25px 0;
    }

    .Especialidades-element {
        width: 210px;
    }

    .Especialidades-element h4 {
        font-size: 21px;
    }
}

@media(max-width: 400px) {
    .Especialidades h2 {
        font-size: 28px;
        margin-bottom: 10px;
    }

    .Especialidades-element {
        width: 190px;
        padding: 15px 0;
    }

    .Especialidades-element h4 {
        font-size: 20px;
    }
}

@media(max-width: 300px) {
    .Especialidades h2 {
        font-size: 26px;
    }

    .Especialidades-element-title {
        height: 70px;
    }
    
    .Especialidades-element h4 {
        font-size: 19px;
    }
}

@media(max-width: 270px) {
    .Especialidades h2 {
        font-size: 24px;
    }
}