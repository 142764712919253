.Nosotros {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 125px 25px;
    width: calc(100% - 50px);
    background-color: #f5f5f5;
}

.Nosotros h2 {
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    font-size: 36px;
    margin:  0 50px 25px 50px;
    text-align: center;
}

.Nosotros p {
    font-family: 'Open Sans', sans-serif;
    max-width: 1101px;
    margin: 0 25px;
    text-align: justify !important;
}

@media(max-width: 1000px) {
    .Nosotros {
        padding: 100px 25px;
    }
}

@media(max-width: 650px) {
    .Nosotros h2 {
        margin: 0 0 25px 0;
    }

    .Nosotros p {
        margin: 0;
    }
}

@media(max-width: 500px) {
    .Nosotros {
        padding: 50px 25px;
    }
}

@media(max-width: 400px) {
    .Nosotros h2 {
        font-size: 28px;
    }
}

@media(max-width: 300px) {
    .Nosotros h2 {
        font-size: 26px;
    }
}

@media(max-width: 270px) {
    .Nosotros h2 {
        font-size: 24px;
    }
}